<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="mb-7">
          <v-toolbar flat>
            <v-toolbar-title>Custom User Upload</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-data-table
              class="mx-5"
              :loading="tableLoading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :search="search"
              :items="allEvent"
              :footer-props="{
                'items-per-page-options': [50, 100, 150, 200, 300, -1]
              }"
              :items-per-page="50"
              :custom-filter="filterOnlyCapsText"
            >
              <!-- <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Search (Event Details)"
                  class="mx-4"
                ></v-text-field>
              </template> -->
              <template v-slot:item="row">
                <tr>
                  <td>{{ row.item.email || '' }}</td>
                  <td>{{ row.item.businessName || '' }}</td>
                  <td>{{ row.item.website || '' }}</td>
                  <td><a @click="showImageModel(row.item)" style="text-decoration: none;">
                      <v-img :src="imageBase + row.item.imageName" :alt="row.item._id" height="50px" width="50px"></v-img>
                    </a>
                    <a :href="imageBase + row.item.imageName" target="_blank" style="text-decoration: none;"><v-icon color="primary">mdi-eye</v-icon> View</a>
                    </td>
                  <td>{{ row.item.phoneNo ? row.item.phoneNo : '' }}</td>
                  <td>{{ dateFormat(row.item.createdAt) || '' }}</td>
                  <td><a @click="showDeleteDilog(row.item)"><v-icon color="red">mdi-delete</v-icon></a>
                  </td>
                </tr>
              </template>
              <template v-slot:no-results>NO RESULTS HERE</template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <image-model :imageUrl="imageFullUrl" :showDownLoad="true" :showModel="isShowImageModel" :headerText="'Image'" @hide="isShowImageModel = false"></image-model>
    <delete-dialog :contentText="deleteText" :showModel="showDeleteModel" :id="deleteId" :headerText="'Delete Banner'" @action="deleteAction"></delete-dialog>
  </v-container>
</template>
<script>

import axios from "axios";
import ImageModel from '../../ImageModel.vue'
import DeleteDialog from '../../DeleteDialog.vue';

export default {
  name: "Custom Upload",
  components: {
    ImageModel,
    DeleteDialog
  },
  data: () => ({
    search: "",
    allEvent:[],
    imageBase:'',
    imageFullUrl: '',
    tableLoading:false,
    deleteId:'',
    showDeleteModel:false,
    deleteText:'',
    headers: [
      { text: "Email", value: "email" },
      { text: "Business Name", value: "businessName" },
      { text: "Website", value: "website" },
      { text: "Banner Url", value: "imageName" },
      { text: "phoneNo", value: "phoneNo" },
      { text: "Created at", value: "createdAt" },
      { text: "Action" },
    ],
    isShowImageModel: false
  }),
  watch:{
  },
  computed: {},
  mounted: function () { 
    this.getCustomUpload();
  },
  created: function () {
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    closeEventModal() {
      this.getCustomUpload();
    },
    async showImageModel(details) {
      this.imageFullUrl = this.imageBase + details.imageName
      this.isShowImageModel = true
    },
    async getCustomUpload() {
      this.tableLoading = true;
      await axios.get(this.Node_JS_HOST + "/api/v1/web/banner-master/user-upload")
        .then((res) => {
          const response = res.data;
          this.allEvent = response.customUploadList;
          this.imageBase = response.imageBase;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.errorHandle(error);
          this.tableLoading = false;
        });
    },
    deleteAction(actionType,id) {
      if (actionType == 'delete') {
        axios.delete(this.Node_JS_HOST + "/api/v1/web/banner-master/user-upload/"+id)
        .then((res) => {
          if(res && res.data._id){
            this.getCustomUpload();
            this.snackbarText = 'Custom upload deleted';
            this.isShowSnackbar = true;
          }
        })
        .catch((error) => {
          this.errorHandle(error);
          this.snackbarText = 'Something went wrong please try again';
          this.isShowSnackbar = true;
        });
      }
      this.showDeleteModel = false;
      this.deleteId = '';
    },
    showDeleteDilog(imageDetails) {
      this.deleteText = 'Are you sure, You want to delete this ?.';
      this.deleteId = imageDetails._id;
      this.showDeleteModel = true;
    },
  },
};
</script>
<style scoped>
  ::v-deep th {
    white-space: nowrap!important;
  }
  .w-100{
    max-width: 100px;
  }
  .w-unset{
    max-width: unset;
  }
</style>